export class Beneficio {
  constructor(
    public ben_id: number,
    public ben_codigo: string,
    public ben_descripcion: string,
    public ben_cantidadcompra: any,
    public ben_cantidadregalo: any,
    public ben_stockmax: any,
    public ben_descuento: any,
    public ben_precioanterior: any,
    public ben_precioahora: any,
    public ben_idProducto: number,
    public ben_idProveedor: number,
    public ben_tipo: number,
    public ben_vigencia: boolean,
    public ben_fechainicio: Date,
    public ben_fechafin: Date,
    public ben_fecharegistro: Date,
    public ben_adminregistro: number,
    public ben_fechaactualiza: Date,
    public ben_adminactualiza: number,
    public ben_producto: string,
    public ben_tipodesc: string,
    public ben_imagen_large: any,
    public ben_imagen_small: any,
    public ben_small_url: any,
    public ben_large_url: any
  ) {}
}
