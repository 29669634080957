import { Component, OnInit, NgModule } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "src/app/models/usuario";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { ConstantsService } from "src/app/constants.service";
import { Tienda } from "src/app/models/tienda";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "inicio",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  recaptchaAvailable = false;
  public title: string;
  public rute: string;
  public usuario_usu: String = "";
  public password_usu: String = "";
  public usuario: Usuario;
  public _vertda: boolean = false;
  public tienda_lst: Array<Tienda>;
  public tienda_dato: number;
  public inicia_sesion: string = "Iniciar Sesión";
  public _load: boolean = false;
  constructor(
    private router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Inicio";
    this.rute = "/inicio";
    this.constants.sesiontda("tda/dashboard");
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.tienda_lst = new Array<Tienda>();
  }

  async login(captchaResponse: string) {
    this._load = true;
    if (this.inicia_sesion == "Ingresar") {
      if (this.tienda_dato.toString() == "0") {
        swal("Inicio de sesión", "Seleccione una tienda.", "info");
      } else {
        this.tienda_lst.forEach((item, index) => {
          if (item.tda_id.toString() == this.tienda_dato.toString()) {
            localStorage.setItem("datostienda_tda", JSON.stringify(item));
            this.constants.sesiontda("tda/dashboard");
          }
        });
      }
    } else {
      // this.spinner.show();

      let _ban = true;
      let _mensaje = "";
      let data = {
        usuario: this.usuario_usu,
        password: this.password_usu,
        tokenrecaptcha: captchaResponse,
        tipo: 0,
        recordar: true,
      };
      // this.spinner.show();
      if (this.usuario_usu.trim() == "" && this.password_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese sus credenciales.";
      } else if (this.usuario_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese usuario.";
      } else if (this.password_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese contraseña.";
      }
      if (_ban) {
        console.log("TOKEN LOGIN: " + JSON.stringify(data));
        await axios
          .post(this.constants.apiUrl + "loginprov", data)
          .then((response) => {
            console.log(response.data);
            let datos = response.data;
            if (datos.estado) {
              let dt_usuario = datos.user[0];
              let usuario_aux = new Usuario(
                dt_usuario.id,
                dt_usuario.usuario,
                dt_usuario.password,
                true,
                0,
                0,
                dt_usuario.idTienda,
                dt_usuario.principal == 1 ? true : false,
                "",
                null,
                null,
                null,
                null,
                "",
                "",
                "",
                datos.token,
                false,
                dt_usuario.tipo,
                dt_usuario.empresa,
                dt_usuario.representante,
                dt_usuario.email,
                dt_usuario.telefono
              );

              console.log(
                "INICIO " + JSON.stringify(JSON.stringify(datos.user[0]))
              );
              if (usuario_aux.us_recordar === true) {
                localStorage.setItem("recordar_tda", JSON.stringify(data));
              } else {
                localStorage.removeItem("recordar_tda");
              }
              console.log("prueba2");
              localStorage.setItem(
                "logindatos_tda",
                JSON.stringify(usuario_aux)
              );
              localStorage.setItem("token_tda", datos.token);
              this.spinner.hide();
              this._load = false;
              swal("Inicio de sesión", datos.mensaje, "success").then(
                (response) => {
                  console.log("prueba");
                  this._vertda = true;
                  this.inicia_sesion = "Ingresar";
                  this.obtener_datos_tienda();
                  // location.href = this.constants.indextienda + "tda/dashboard";
                }
              );
            } else {
              this.spinner.hide();
              this._load = false;
              this._vertda = false;
              swal("Inicio de sesión", datos.mensaje, "info").then(
                (response) => {
                  localStorage.removeItem("token_tda");
                }
              );
            }
          })
          .catch(function (error) {
            this._vertda = false;
            this._load = false;
            this.spinner.hide();
            swal("Ha ocurrido un error al registrar!", error.message, "error");
            console.log(error);
          });
      } else {
        this.spinner.hide();
        this._load = false;
        swal("Inicio de sesión", _mensaje, "info");
      }
    }
  }

  async obtener_datos_tienda() {
    this.spinner.hide();
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idusuario: this.constants.usuario_tda.us_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda_usuario");
    this.tienda_lst = new Array<Tienda>();
    await axios
      .post(this.constants.apiUrl + "lista_tienda_usuario", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          let tienda: Tienda = new Tienda(
            0,
            "SELECCIONE TIENDA...",
            0,
            "",
            "",
            "",
            true,
            null,
            null,
            null,
            null, 0
          );
          this.tienda_lst.push(tienda);
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre,
              0,
              tienda_lst_aux[element].direccion,
              tienda_lst_aux[element].referencia,
              tienda_lst_aux[element].ciudad,
              true,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado
            );
            this.tienda_lst.push(tienda);
          }
          this._vertda = true;
          this.tienda_dato = 0;
        } else {
          this._vertda = false;
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  cancelar_sesion() {
    this.usuario_usu = "";
    this.password_usu = "";
    this._vertda = false;
    this.tienda_lst = Array<Tienda>();
    this.inicia_sesion = "Iniciar Sesión";
    localStorage.removeItem("logindatos_tda");
    localStorage.removeItem("datostienda_tda");
    localStorage.removeItem("token_tda");
  }

  recibirBoton($event) {
    this.usuario_usu = "";
    this.password_usu = "";
    this._vertda = false;
    this.tienda_lst = Array<Tienda>();
    this.inicia_sesion = "Iniciar Sesión";
    localStorage.removeItem("logindatos_tda");
    localStorage.removeItem("datostienda_tda");
    localStorage.removeItem("token_tda");
  }
}
