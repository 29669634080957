import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { routing, AppRoutingProviders } from "./app-routing.module";
import { ConstantsService } from "./constants.service";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule, WavesModule } from 'angular-bootstrap-md';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule } from "ng-recaptcha";
import { NO_ERRORS_SCHEMA } from '@angular/core';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';// If You need animations
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/sites/template/header.component";
import { FooterComponent } from "./components/sites/template/footer.component";
import { HomeComponent } from "./components/sites/home.component";
import { OlvideClaveComponent } from "./components/sites/olvideclave.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CambioPasswordComponent } from "./components/sites/cambiopassword.component";
import { DashboardComponent } from "./components/sites/dashboard.component";
import { CabeceraTdaComponent } from "./components/sites/template/cabeceratda.component";
import { MenuTdaComponent } from "./components/sites/template/menutda.component";
import { TituloTdaComponent } from "./components/sites/template/titulotda.component";
import { DatePipe } from "@angular/common";
import { ConfigTdaComponent } from "./components/sites/configtda.component";
import { BandejaComponent } from "./components/sites/bandeja.component";
import { RegTiendaComponent } from "./components/sites/modules/regtienda.component";
import { RegUsuarioComponent } from "./components/sites/modules/regusuarioperfil.component";
import { CierreCajaComponent } from "./components/sites/modules/regcierrecaja.component";
import { RegCobrosComponent } from "./components/sites/modules/regcobros.component";
import { RegDevolucionComponent } from "./components/sites/modules/regdevolucion.component";
import { IngresoProdComponent } from "./components/sites/modules/regingresoprod.component";
import { InventarioComponent } from "./components/sites/modules/reginventario.component";
import { RegPagosComponent } from "./components/sites/modules/regpagos.component";
import { RegPedidoComponent } from "./components/sites/modules/regpedidos.component";
import { RegVentaComponent } from "./components/sites/modules/regventa.component";
import { BeneficiosComponent } from "./components/sites/modules/regbeneficios.component";

import { A11yModule } from "@angular/cdk/a11y";
// import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
// import { ChartsModule, WavesModule } from 'angular-bootstrap-md';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { GoogleChartsModule } from 'angular-google-charts';
import { YouTubePlayerModule } from "@angular/youtube-player";

import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { RegProductoComponent } from "./components/sites/modules/regproducto.component";
import { EstadisticasComponent } from "./components/sites/modules/regestadisticas.component";
import { NuevaClaveComponent } from "./components/sites/nuevaclave.component";
// import { PublicidadTdaComponent } from "./components/sites/template/publicidadtda.component";
@NgModule({
  exports: [
    A11yModule,
    // ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    OlvideClaveComponent,
    NuevaClaveComponent,
    CambioPasswordComponent,
    DashboardComponent,
    CabeceraTdaComponent,
    MenuTdaComponent,
    TituloTdaComponent,
    ConfigTdaComponent,
    BandejaComponent,
    RegTiendaComponent,
    RegUsuarioComponent,
    CierreCajaComponent,
    RegCobrosComponent,
    RegDevolucionComponent,
    IngresoProdComponent,
    InventarioComponent,
    RegPagosComponent,
    RegPedidoComponent,
    RegVentaComponent,
    RegProductoComponent,
    BeneficiosComponent,
    EstadisticasComponent,
    // PublicidadTdaComponent
  ],
  imports: [
    BrowserModule,
    routing,
    NgxSpinnerModule,
    RecaptchaModule,
    BrowserAnimationsModule,
    // RecaptchaModule,
    ChartsModule,
    CarouselModule.forRoot(), WavesModule.forRoot(),
    // MatNativeDateModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    // MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    GoogleChartsModule,
    YouTubePlayerModule
  ],
  providers: [
    // AppRoutingProviders,
    ConstantsService,
    DatePipe,
    ThemeService
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: "6Lc2Of8UAAAAAHTO1VB96KHQNOk8GgPzTMgGuoqw",
    // },
  ],
  entryComponents: [CambioPasswordComponent],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
// platformBrowserDynamic().bootstrapModule(AppModule);
