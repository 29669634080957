import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Usuario } from "./models/usuario";
import axios from "axios";
import { Tienda } from "./models/tienda";
import { Perfil } from "./models/perfil";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Producto } from "./models/producto";
const swal: SweetAlert = _swal as any;
import { environment } from "./../environments/environment";
import { Cliente } from "./models/cliente";
import { DetallePedido } from "./models/pedido";
import { Categoria } from './models/categoria';
import { JsonpClientBackend } from "@angular/common/http";
@Injectable()
export class ConstantsService {
  constructor(private router: Router, public spinner: NgxSpinnerService) {}
  readonly apiUrl: string = environment.URLAPI;
  readonly index: string = environment.INDEX;
  readonly indextienda: string = environment.INDEXTDA;
  readonly keyrecaptcha: string = environment.SITEKEY;
  public datos_tda:Tienda;
  public monto_fiado:any;
  public ciudad_lst:Array<Categoria> = new Array<Categoria>()
  public productos_lst: Array<Producto>;
  public productostda_lst: Array<Producto>;
  public productosvta_lst: Array<Producto>;
  public productos_fil: Array<Producto>;
  public clientes_lst: Array<Cliente>;
  public clientes_fil: Array<Cliente>;
  public pedido_cart: Array<DetallePedido> = new Array<DetallePedido>();
  public prov_cart: number[] = [];
  public envase_lst:Array<Categoria> = new Array<Categoria>()
  public unidadmedida_lst:Array<Categoria> = new Array<Categoria>()
  public publicidad_ban:boolean = true
  public publicidad_hora:any = new Date();
  public estado_pedido: Array<string> = [
    "REGISTRADO",
    "EDITANDO",
    "REVISANDO",
    "POR DESPACHAR",
    "ATENDIDO PARCIAL",
    "DESPACHADO",
    "RECIBIDO PARCIAL",
    "RECIBIDO",
    "ANULADO",
  ];
  public tipopago: Array<string> = [
    "CRÉDITO/FIADO",
    "PAGO PARCIAL",
    "PAGADO",
    "DEVUELTO",
  ];
  public tipocaja: Array<string> = [
    "COMPRA",
    "VENTA",
    "DEVOLUCIÓN VENTA",
    "CANCELAR COMPRA",
  ];
  public headers = new Headers({
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  });

  public headers_aut = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token_tda"),
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    // 'Access-Control-Allow-Origin': "*"
  });

  public headers_formimage = new Headers({
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Bearer " + localStorage.getItem("token_tda"),
    // 'Access-Control-Allow-Origin': "*"
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  });

  public usuario_tda: Usuario = JSON.parse(
    localStorage.getItem("logindatos_tda")
  );

  public datostienda: Tienda = JSON.parse(
    localStorage.getItem("datostienda_tda")
  );

  public token_gen = localStorage.getItem("token_tda");

  public perfilpermiso_lst: Array<Perfil>;

  public cerrar_sesiontda(): void {
    localStorage.removeItem("token_tda");
    localStorage.removeItem("logindatos_tda");
    localStorage.removeItem("datostienda_tda");
    location.href = this.indextienda;
  }

  public permisotda_existe(ruta: string) {
    let _ban = false;
    this.perfilpermiso_lst = new Array<Perfil>();
    this.obtener_perfiles().then(() => {
      console.log("PEMRISOS " + JSON.stringify(this.perfilpermiso_lst));
      this.perfilpermiso_lst.forEach((element) => {
        console.log("RUTA \n" + element.pf_ruta);
        console.log(ruta);
        if (element.pf_ruta == ruta) {
          _ban = true;
        }
      });
      if (!_ban) {
        swal(
          "Seguridad Aplicación ",
          "Usted no tiene acceso a este módulo",
          "warning"
        ).then(() => {
          this.router.navigate(["tda/dashboard"]);
        });
      }
    });
  }

  public permisoconfig_existe(_ban: Boolean) {
    console.log("ACCESO " + _ban);
    if (!_ban) {
      swal(
        "Seguridad Aplicación ",
        "Usted no tiene acceso a este módulo",
        "warning"
      ).then(() => {
        this.router.navigate(["tda/dashboard"]);
      });
    }
  }

  public sesiontda(ruta: string) {
    console.log(localStorage.getItem("token_tda"));
    console.log(localStorage.getItem("logindatos_tda"));
    if (localStorage.getItem("token_tda") !== null) {
      if (localStorage.getItem("logindatos_tda") !== null) {
        console.log(
          "DATOS USUARIO \n" +
            JSON.stringify(JSON.parse(localStorage.getItem("logindatos_tda")))
        );
        this.usuario_tda = JSON.parse(localStorage.getItem("logindatos_tda"));
        if (localStorage.getItem("datostienda_tda") !== null) {
          this.datostienda = JSON.parse(
            localStorage.getItem("datostienda_tda")
          );
          // window.location.href = this.indextienda + ruta
          this.router.navigate([ruta]);
        } else {
          localStorage.removeItem("logindatos_tda");
          localStorage.removeItem("datostienda_tda");
          this.router.navigate(["/"]);
        }
      } else {
        localStorage.removeItem("logindatos_tda");
        localStorage.removeItem("datostienda_tda");
        this.router.navigate(["/"]);
      }
    } else {
      localStorage.removeItem("logindatos_tda");
      localStorage.removeItem("datostienda_tda");
      this.router.navigate(["/"]);
    }
  }

  public async obtener_perfiles() {
    this.spinner.hide();
    console.log(JSON.stringify(this.usuario_tda));
    let request = {
      idtienda: this.datostienda.tda_id,
      idusuario: this.usuario_tda.us_id,
      token: this.usuario_tda.us_token,
    };
    console.log("USUARIO TIENDA " + JSON.stringify(request));
    console.log(this.apiUrl + "lista_perfiles_usuario");
    this.perfilpermiso_lst = new Array<Perfil>();
    await axios
      .post(this.apiUrl + "lista_perfiles_usuario", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let perfil_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in perfil_lst_aux) {
            let perfil: Perfil = new Perfil(
              perfil_lst_aux[element].id,
              perfil_lst_aux[element].codigo,
              perfil_lst_aux[element].descripcion,
              perfil_lst_aux[element].vigencia
            );
            this.perfilpermiso_lst.push(perfil);
          }
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  closeMenu() {
    var boxOne = document.getElementById("global-side-nav");
    boxOne.classList.add("horizTranslateRev");
    boxOne.classList.remove("horizTranslate");
  }

  async listado_ciudades() {
    this.ciudad_lst = new Array<Categoria>();

    let request = {};

    await axios
      .post(this.apiUrl + "list_ciudades", request, {
        headers: this.headers,
      })
      .then((response) => {

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                0,
                "",
                categoria_lst_aux[element].descripcion,
                true,
                0,
                0,
                null,
                null,
                null,
                null,
               "",
                ""
              );
              // console.log("num " + element);
              this.ciudad_lst.push(categoria_aux);

            }

          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async listado_productos($id: number) {
    // this.spinner.show();
    let request = {
      id: $id,
    };
    this.productos_lst = new Array<Producto>();
    this.productos_fil = new Array<Producto>();
    console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "lista_productos", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log("LISTADO DE PRODUCTOS PEDIDOS " + JSON.stringify(response.data));

        let productos_lst_aux = response.data.data;
        let producto_aux: Producto;
        if (response.data.estado) {
          if (productos_lst_aux.length > 0) {
            for (let element in productos_lst_aux) {
              //console.log("num " + element);

              producto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].imagen,
                parseFloat(productos_lst_aux[element].cantidad).toFixed(2),
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                0.0,
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                (productos_lst_aux[element].empresa == null ? 'ADMINISTRADOR' : productos_lst_aux[element].empresa.toString().toUpperCase()),
                this.apiUrl +
                  "storage?ruta=" +
                  productos_lst_aux[element].imagen,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                0,
                1.0, 0
              );
              this.productos_fil.push(producto_aux);
            }
            this.productos_lst = this.productos_fil;
          }

          // this.spinner.hide();
        }
        // else{
        //   // this.spinner.hide();
        //   swal("Productos", response.data.mensaje,"info");
        // }
      })
      .catch(function (error) {
        // this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_productos_filtro($id: number, $idcategoria: number, $turno:string) {
    this.spinner.show();
    let request = {
      id: $id,
      idcateg: $idcategoria,
      turno: $turno
    };
    this.productos_lst = new Array<Producto>();
    this.productos_fil = new Array<Producto>();
    console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "lista_productos_filtro", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log("LISTADO DE PRODUCTOS PEDIDOS " + response.data);

        let productos_lst_aux = response.data.data;
        let producto_aux: Producto;
        if (response.data.estado) {
          if (productos_lst_aux.length > 0) {
            for (let element in productos_lst_aux) {
              //console.log("num " + element);

              producto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].imagen,
                parseFloat(productos_lst_aux[element].cantidad).toFixed(2),
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                0.0,
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                productos_lst_aux[element].empresa.toString().toUpperCase(),
                this.apiUrl +
                  "storage?ruta=" +
                  productos_lst_aux[element].imagen,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                0,
                1.0, 0
              );
              this.productos_fil.push(producto_aux);
            }
            this.productos_lst = this.productos_fil;
          }

          this.spinner.hide();
        }else{
          this.spinner.hide();
          swal("Productos", response.data.mensaje,"info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_productos_tda($id: number) {
    this.spinner.show();
    let request = {
      id: $id,
    };
    this.productos_lst = new Array<Producto>();
    this.productos_fil = new Array<Producto>();
    console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "lista_productos_tda", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let productos_lst_aux = response.data.data;
        let producto_aux: Producto;
        if (response.data.estado) {
          if (productos_lst_aux != null) {
            for (let element in productos_lst_aux) {
              //console.log("num " + element);

              producto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].imagen,
                0.0,
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                0.0,
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                productos_lst_aux[element].adminactualiza,
                this.apiUrl +
                  "storage?ruta=" +
                  productos_lst_aux[element].imagen,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                0,
                1.0,  productos_lst_aux[element].idProductoGral
              );
              this.productos_fil.push(producto_aux);
            }
          }
          this.productostda_lst = this.productos_fil;
        } else {
          swal("Registro de productos", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_productos_vta($id: number) {
    this.spinner.show();
    let request = {
      id: $id,
    };
    this.productosvta_lst = new Array<Producto>();
    // this.productos_fil = new Array<Producto>();

    await axios
      .post(this.apiUrl + "lista_productos_vta", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log(JSON.stringify(response.data));
        this.spinner.hide();
        let productos_lst_aux = response.data.data;
        let producto_aux: Producto;
        if (response.data.estado) {
          console.log("PRODUCTOS A VENDER: " + JSON.stringify(response.data));
          if (productos_lst_aux != null) {
            for (let element in productos_lst_aux) {
              //console.log("num " + element);

              producto_aux = new Producto(
                productos_lst_aux[element].id,
                productos_lst_aux[element].codigo,
                productos_lst_aux[element].descripcion,
                productos_lst_aux[element].empresa,
                0,
                parseFloat(productos_lst_aux[element].precio).toFixed(2),
                0.0,
                productos_lst_aux[element].idProveedor,
                productos_lst_aux[element].idUnidad,
                productos_lst_aux[element].unidad,
                productos_lst_aux[element].idEnvase,
                productos_lst_aux[element].envase,
                productos_lst_aux[element].vigencia == 1 ? true : false,
                productos_lst_aux[element].fecharegistro,
                productos_lst_aux[element].adminregistro,
                productos_lst_aux[element].fechaactualiza,
                productos_lst_aux[element].adminactualiza,
                this.apiUrl +
                  "storage?ruta=" +
                  productos_lst_aux[element].imagen,
                productos_lst_aux[element].idCategoria,
                productos_lst_aux[element].categoria,
                productos_lst_aux[element].superior,
                productos_lst_aux[element].stock,
                1.0, 0
              );
              this.productosvta_lst.push(producto_aux);
            }
          }
          // this.productos_fil = this.productosvta_lst;
          console.log("PEDIDOS PARA ORDEN VENTA" + JSON.stringify(this.productosvta_lst))
        }
        // else {
        //   swal("Registro de productos", response.data.message, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async listado_categoria($id:number) {
    this.envase_lst = new Array<Categoria>();
    this.unidadmedida_lst = new Array<Categoria>();

    let request = {
      id: $id,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "list_categoria", request, {
        headers: this.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              // console.log("num " + element);
              if($id == 1){
                this.envase_lst.push(categoria_aux);
              }else if($id == 2){
                this.unidadmedida_lst.push(categoria_aux);
              }
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async listado_cliente($id: number) {
    this.spinner.show();
    let request = {
      id: $id,
    };
    this.clientes_lst = new Array<Cliente>();
    this.clientes_fil = new Array<Cliente>();
    console.log(JSON.stringify(request));
    await axios
      .post(this.apiUrl + "lista_clientes", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          if (response.data.data != null) {
            this.clientes_fil = response.data.data;
            this.clientes_lst = response.data.data;
          }
        }
        // else {
        //   swal("Registro de clientes", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }


  async obtener_datos_tda() {
    this.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    console.log("USUARIO TIENDA MONTO " + JSON.stringify(this.usuario_tda))
    let request = {
      idtienda: tiendaaux.tda_id,
      idusuario: this.usuario_tda.us_id,
      token: this.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.apiUrl + "lista_usuario_tienda");
    this.datos_tda = new Tienda(0,"",0,"","","",false, null,null, null, null, 0);
    await axios
      .post(this.apiUrl + "lista_usuario_tienda", request, {
        headers: this.headers_aut,
      })
      .then((response) => {

        let tienda_lst_aux = response.data.data;
        console.log("RESPONSE USUARIO TIENDA " + JSON.stringify(tienda_lst_aux[0].fiado));
        if (response.data.estado) {
          this.datos_tda = new Tienda(
              tienda_lst_aux[0].id,
              tienda_lst_aux[0].nombre,
              0,
              tienda_lst_aux[0].direccion,
              tienda_lst_aux[0].referencia,
              tienda_lst_aux[0].ciudad,
              true,
              null,
              null,
              null,
              null, tienda_lst_aux[0].fiado
            );
        }
      })
      .catch(function (error) {
        // swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }


  async obtener_montofiado_tda() {
    this.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.usuario_tda.us_token,
    };
    this.monto_fiado = 0.0;
    console.log(JSON.stringify(request));
    console.log(this.apiUrl + "monto-fiado");
    await axios
      .post(this.apiUrl + "monto-fiado", request, {
        headers: this.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          this.monto_fiado = tienda_lst_aux[0].deuda;
        }
      })
      .catch(function (error) {
        swal("Ha ocurrido un error al registrar!", error.mensaje, "error");
        console.log("tipo error " + error);
      });
  }
}

// swal({
//   title: `${pregunta}`,
//   text: `${titulo}`,
//   icon: "warning",
//   buttons: ["Cancelar", true],
// }).then((willDelete) => {
//   if (willDelete) {

//   }
// });
