export class Categoria {
  constructor(
    public cat_id: number,
    public cat_codigo: string,
    public cat_descripcion: string,
    public cat_vigencia: Boolean,
    public cat_superior: number,
    public cat_idCategoria: number,
    public cat_fecharegistro: Date,
    public cat_adminregistro: number,
    public cat_fechaactualiza: Date,
    public cat_adminactualiza: number,
    public cat_categoria: string,
    public cat_superiordesc: string
  ) {}
}
