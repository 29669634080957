import { Component, OnInit, NgModule } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import 'rxjs/add/operator/filter';
import { Usuario } from "src/app/models/usuario";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { ConstantsService } from "src/app/constants.service";
import { Tienda } from "src/app/models/tienda";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "inicio",
  templateUrl: "./olvideclave.component.html",
  styleUrls: ["./home.component.css"],
})
export class OlvideClaveComponent implements OnInit {
  recaptchaAvailable = false;
  public title: string;
  public rute: string;
  public usuario_usu: String = "";
  public email_usu: String = "";
  public tipo_usu: number =  0
  public _load: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Olvide mi contraseña";
    this.rute = "/olvideclave";
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.route.queryParams.filter(params => params.tipo)
      .subscribe(params => {
        this.tipo_usu = params.tipo;
      }
    );
    this.cancel()
  }

  async login(captchaResponse: string) {
    this._load = true;

      // this.spinner.show();

      let _ban = true;
      let _mensaje = "";
      let data = {
        usuario: this.usuario_usu,
        email: this.email_usu,
        tokenrecaptcha: captchaResponse,
        tipo: this.tipo_usu
      };
      // this.spinner.show();
      if (this.usuario_usu.trim() == "" && this.email_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese sus datos.";
      } else if (this.usuario_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese usuario.";
      } else if (this.email_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese email.";
      }
      if (_ban) {
        console.log("TOKEN LOGIN: " + JSON.stringify(data));
        await axios
          .post(this.constants.apiUrl + "olvide_clave", data)
          .then((response) => {
            console.log(response.data);
            let datos = response.data;
            if (datos.estado) {
              this.spinner.hide();
              this._load = false;
              swal("Recuperar contraseña", datos.mensaje, "success").then(
                (response) => {

                  switch(this.tipo_usu){
                    case 0: location.href = this.constants.indextienda; break;
                    case 1: location.href = this.constants.index + '#/business'; break;
                    case 2: location.href = this.constants.index + '#/business'; break;
                    case 3: location.href = this.constants.index + '#/business'; break;
                    case 4: location.href = this.constants.index + '#/adminlogin'; break;
                  }
                  this.cancel();
                  // location.href = this.constants.indextienda + "tda/dashboard";
                }
              );
            } else {
              this.spinner.hide();
              this._load = false;
              swal("Recuperar contraseña", datos.mensaje, "info");
            }
          })
          .catch(function (error) {
            this._load = false;
            this.spinner.hide();
            swal("Ha ocurrido un error al registrar!", error.message, "error");
            console.log(error);
          });
      } else {
        this.spinner.hide();
        this._load = false;
        swal("Inicio de sesión", _mensaje, "info");
      }

  }

  cancel() {
    this.usuario_usu = "";
    this.email_usu = "";
  }
}
