import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Perfil } from "../../models/perfil";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Categoria } from "src/app/models/categoria";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "dashboardprov",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public title: string;
  public rute: string;
  public categoria_lst:Array<Categoria>
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer

  ) {
    this.title = "Instrucciones de uso";
    this.rute = "tda/dashboard";

    console.log("USUARIO TDA " + JSON.stringify(this.constants.usuario_tda));
  }
  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();
    this.categoria_lst=Array<Categoria>()
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.constants.perfilpermiso_lst = new Array<Perfil>();
      this.constants.obtener_perfiles();
      this.constants.sesiontda(this.rute);
      this.constants.usuario_tda = JSON.parse(
        localStorage.getItem("logindatos_tda")
      );
      this.spinner.hide();
    }, 2500);
    this.listado_categoria()
    // Este código carga el reproductor de la API en un iframe de manera asíncrona, siguiendo las instrucciones:
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }
  // ngOnInit(){
  //   console.log('login.component cargado!');
  // }

  getVideoIframe(url) {
    // var video, results;

    // if (url === null) {
    //     return '';
    // }
    // results = url.match('[\\?&]v=([^&#]*)');
    // video   = (results === null) ? url : results[1];

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + url);
}

async listado_categoria() {
  this.categoria_lst = new Array<Categoria>();

  let request = {
    id: 10,
    superior: 0,
  };
  console.log(JSON.stringify(request));
  await axios
    .post(this.constants.apiUrl + "list_categoria", request, {
      headers: this.constants.headers,
    })
    .then((response) => {
      console.log("categoria \n" + JSON.stringify(response.data));

      if (response.data.estado) {
        if (response.data.data != null) {
          let categoria_lst_aux = response.data.data;
          let categoria_aux: Categoria;
          for (let element in categoria_lst_aux) {
            // console.log("num " + element);
            if(categoria_lst_aux[element].vigencia == 1){
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoria_lst.push(categoria_aux);
            }
          }
        }
        console.log("PRODUCTOS " + JSON.stringify(this.categoria_lst));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

}



