import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
import { Categoria } from 'src/app/models/categoria';
import { Beneficio } from 'src/app/models/beneficio';
import { NgbModal,ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Tienda } from 'src/app/models/tienda';
import { Canje } from "src/app/models/canje";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "beneficios",
  templateUrl: "./regbeneficios.component.html",
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
      .modal-dialog{max-width:100% !important}
    `,

  ],
})
export class BeneficiosComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipobenef: String;
  public prod_idCategoria: number;
  public prod_superior: number;
  public categoria_lst: Array<Categoria>;
  public subcategoria_lst: Array<Categoria>;
  public categoriaproducto_lst: Array<Categoria>;
  public canje_lst: Array<Canje>;
  public beneficios_lst: Array<Beneficio>;
  public _ver:boolean = true;
  public closeResult = "";
  fechafin:any;
  fechainicio:any;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  hoveredDate: NgbDate;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    public formatterdt: NgbDateParserFormatter,
  ) {
    this.title = "Beneficios";
    this.rute = "tda/beneficios";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
    this.tipobenef = "0";
    this.categoria_lst = new Array<Categoria>();
    this.subcategoria_lst = new Array<Categoria>();
    this.categoriaproducto_lst = new Array<Categoria>();
    this.beneficios_lst = new Array<Beneficio>();

    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );
    this.listado_categoria();
    this.listado_subcategoria();
    this.filtrar_beneficios();
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
  }

  vercodigos($ban:boolean){
    this._ver = $ban;
    if(this._ver){
      this.prod_superior =0;
      this.prod_idCategoria = 0;
      this.tipobenef = "0";
      this.filtrar_beneficios();
    }else{
      this.toDate = this.calendar.getToday();
      this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -10);
      this.fechainicio = this.fromDate.year + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + this.fromDate.day.toString().padStart(2, "0")
      this.fechafin =  this.toDate.year + "-" + this.toDate.month.toString().padStart(2, "0") + "-" + this.toDate.day.toString().padStart(2, "0")
      this.listar();
    }
  }
  async listado_categoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 4,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          this.categoria_lst.push(
            new Categoria(
              0,
              "",
              "TODOS",
              false,
              0,
              0,
              null,
              0,
              null,
              0,
              "",
              ""
            )
          );
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoria_lst.push(categoria_aux);
            }
          }
          this.prod_idCategoria = 0;
          this.prod_superior = 0;
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async listado_subcategoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 5,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoriaproducto_lst.push(categoria_aux);
            }
          }
          console.log("PRODUCTOS " + JSON.stringify(this.categoriaproducto_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  seleccionar_superior($id: number, $ban: boolean) {
    let categoria_aux: Categoria;
    this.subcategoria_lst = new Array<Categoria>();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );

    if ($id > 0) {
      console.log("SUPERIOR " + $id);
      this.categoriaproducto_lst.forEach((item, index) => {
        if (item.cat_superior == $id) {
          categoria_aux = new Categoria(
            item.cat_id,
            item.cat_codigo,
            item.cat_descripcion,
            item.cat_vigencia,
            item.cat_superior,
            item.cat_idCategoria,
            null,
            0,
            null,
            0,
            "",
            ""
          );
          this.subcategoria_lst.push(categoria_aux);
        }
      });
    }
  }

  async filtrar_beneficios()  {
    this.spinner.show();
    this.beneficios_lst = new Array<Beneficio>();

    let request = {
      tipo: this.tipobenef,
      subcategoria: this.prod_idCategoria,
      categoria: this.prod_superior,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "beneficios-app", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("Beneficios \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let beneficios_lst_aux = response.data.data;
            let beneficio_aux: Beneficio;
            beneficio_aux = new Beneficio(
              0,
              "",
              "",
              0,
              0,
              0,
              0,
              parseFloat("0").toFixed(2),
              parseFloat("0").toFixed(2),
              0,
              0,
              0,
              false,
              null,
              null,
              null,
              null,
              null,
              null,
              "",
              "",
              "",
              "",
              null,
              null
            );
            for (let element in beneficios_lst_aux) {
               console.log("num " + element);
               beneficio_aux = new Beneficio(
                beneficios_lst_aux[element].id,
                beneficios_lst_aux[element].codigo,
                beneficios_lst_aux[element].descripcion,
                0,0,0,0,0,0,0,beneficios_lst_aux[element].idProveedor,
                parseInt(this.tipobenef.toString()), true, beneficios_lst_aux[element].fechainicio,
                beneficios_lst_aux[element].fechafin, null, 0, null,0, beneficios_lst_aux[element].empresa,
                beneficios_lst_aux[element].tipo, this.constants.apiUrl +
                "storage?ruta=" + beneficios_lst_aux[element].imagen_large,
                this.constants.apiUrl +
                "storage?ruta=" + beneficios_lst_aux[element].imagen_small,
                this.constants.apiUrl +
                "storage?ruta=" + beneficios_lst_aux[element].imagen_small,
                this.constants.apiUrl +
                "storage?ruta=" + beneficios_lst_aux[element].imagen_large
              );
              this.beneficios_lst.push(beneficio_aux);
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        this.spinner.hide();
      })
      .catch(function (error) {
        this.spinner.hide();
        console.log(error);
      });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  filtrar(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  async generarcodigo($prod:Beneficio){
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    var date = new Date();
    var dia = date.getDate();
    var mes = date.getMonth() + 1;
    var yyy = date.getFullYear();
    var min = date.getMinutes();
    var seg = date.getSeconds();
    var hora = date.getHours();
    let codigo_beneficio = $prod.ben_codigo + dia.toString() + seg.toString() + mes.toString()  + min.toString() + yyy.toString() + hora.toString() +tienda_aux.tda_id.toString().padStart(6,"0");
    console.log(codigo_beneficio);

    this.spinner.show();

    let request = {
      codigo: codigo_beneficio,
      idBeneficio: $prod.ben_id,
      idNegocio: tienda_aux.tda_id,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "canje-app", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("Canje \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          swal("Registro de Código", "Se realizó el registro de su código: " + codigo_beneficio, "success");
        }
        this.spinner.hide();
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

   //FECHAS
   onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatterdt.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  async listar(){
    this.spinner.show();
    this.canje_lst = new Array<Canje>();
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    let request = {
      id: tienda_aux.tda_id,
      tipo: 0,
      fechainicio:
        this.fromDate.year.toString() +
        "-" +
        this.fromDate.month.toString().padStart(2, "00") +
        "-" +
        this.fromDate.day.toString().padStart(2, "00"),
        fechafin:
        this.toDate.year.toString() +
        "-" +
        this.toDate.month.toString().padStart(2, "00") +
        "-" +
        this.toDate.day.toString().padStart(2, "00"),
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "canje-list", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("ListaCanje \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          let canje_aux = response.data.data;
          for (let element in canje_aux) {
            canje_aux[element].imagen_small =
                this.constants.apiUrl +
                  "storage?ruta=" +
                  canje_aux[element].imagen_small
                  canje_aux[element].imagen_large =
                this.constants.apiUrl +
                  "storage?ruta=" +
                  canje_aux[element].imagen_large
                  canje_aux[element].fechacanje = (canje_aux[element].fechacanje  == null ? '' : canje_aux[element].fechacanje)
            this.canje_lst.push(canje_aux[element]);
          }

          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        console.log(JSON.stringify(this.canje_lst))
        this.spinner.hide();
      })
      .catch(function (error) {
        this.spinner.hide();
        console.log(error);
      });
  }
}


