import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router} from "@angular/router";
import { ModalDismissReasons, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CambioPasswordComponent } from "../cambiopassword.component";
import { Usuario } from "../../../models/usuario";
import { ConstantsService } from "../../../constants.service";
import { Tienda } from '../../../models/tienda';
import axios from "axios";
import { Publicidad } from "src/app/models/publicidad";

// import { PublicidadTdaComponent } from "./publicidadtda.component";
const MODALS = {
  changepass: CambioPasswordComponent,
};



@Component({
  selector: "cabeceratda",
  templateUrl: "./cabeceratda.component.html",
  styles: [`ngb-modal-window .modal-dialog{max-width: 1900px !important;}`]

})
export class CabeceraTdaComponent implements OnInit {
  @Input() usuario_cab: Usuario;
  @Input() titulo_form: String;
  public tienda_dato: number = 0;
  public tienda_lst: Array<Tienda>;
  public publicidad_lst: Array<Publicidad>;
  public closeResult = "";
  public _name = "";

  @ViewChild("contentPublicidad",   null) contentPublicidad;
  private modalRef: NgbModalRef;
  constructor(
    // public _router : ActivatedRoute,
    public _modalService: NgbModal,
    public router: Router,
    public constants: ConstantsService,
    private modalService: NgbModal
  ) {
    console.log("US " + JSON.stringify(this.constants.usuario_tda));

    this.obtener_datos_tienda().then(() => {
      let tienda_aux: Tienda = JSON.parse(
        localStorage.getItem("datostienda_tda")
      );
      this.tienda_dato = tienda_aux.tda_id;
      this.usuario_cab = this.constants.usuario_tda;
    });

    // this.openLockedScreen()
  }

  ngOnInit(){
    console.log("cargado componenet")
    this.publicidades()
  }

  tiempo_transcurrido(){
    let hora:any = new Date().getTime();
    let horaant:any = this.constants.publicidad_hora.getTime()
    let tiempo = (hora - horaant)/1000/60;
    if(tiempo >= 5){
      this.constants.publicidad_ban = true
      this.constants.publicidad_hora = new Date()
    }
    console.log("TIEMPO TRANSCURRIDO: " + tiempo)
    if(this.constants.publicidad_ban){
      this.openpub();
    }
  }

  public logoutda(): void {
    localStorage.removeItem("token_tda");
    localStorage.removeItem("logindatos_tda");
    // this.router.navigate
    // location.href = this.constants.indextienda;
  }

  open(name: string) {
    this._modalService.open(MODALS[name]);
  }

  async obtener_datos_tienda() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idusuario: this.constants.usuario_tda.us_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda_usuario");
    this.tienda_lst = new Array<Tienda>();
    await axios
      .post(this.constants.apiUrl + "lista_tienda_usuario", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          let tienda: Tienda;
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre,
              0,
              tienda_lst_aux[element].direccion,
              tienda_lst_aux[element].referencia,
              tienda_lst_aux[element].ciudad,
              true,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado,
            );
            this.tienda_lst.push(tienda);
            this.tienda_dato = 0;
          }
        }
      })
      .catch(function (error) {
        console.log("tipo error " + error.message);
      });
  }


  async publicidades() {
    let request = {
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "publicidades-app");
    this.publicidad_lst = new Array<Publicidad>();
    await axios
      .post(this.constants.apiUrl + "publicidades-app", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        let publicidad_lst_aux = response.data.data;
        if (response.data.estado) {
          if(publicidad_lst_aux.length > 0){
            for (let element in publicidad_lst_aux) {
              this.publicidad_lst.push(new Publicidad(publicidad_lst_aux[element].id,
                this.constants.apiUrl +
                    "storage?ruta=" +
                    publicidad_lst_aux[element].img_small,
                    this.constants.apiUrl +
                    "storage?ruta=" +
                    publicidad_lst_aux[element].img_large,
                publicidad_lst_aux[element].descripcion
                ));
            }
          }
        }
        console.log("total_publicidades: " + this.publicidad_lst.length)
        if(this.publicidad_lst.length > 0){
          this.tiempo_transcurrido()
        }
      })
      .catch(function (error) {
        console.log("tipo error " + error.message);
      });
  }
  cambiar_tienda() {
    this.tienda_lst.forEach((item, index) => {
      if (item.tda_id.toString() == this.tienda_dato.toString()) {
        localStorage.setItem("datostienda_tda", JSON.stringify(item));
        window.location.reload();
      }
    });
  }

  openpub() {

    this.modalService
    // this.modalRef = this._modalService.open(this.contentPublicidad);
    // this.modalRef.result.catch(() => { });
      .open( this.contentPublicidad, { ariaLabelledBy: "modal-basic-title",  windowClass : "modal-dialog-public" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );


  }

  closeModal() {
    this.constants.publicidad_ban = false;
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
