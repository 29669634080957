export class Cliente {
  constructor(
    public id: number,
    public alias: string,
    public apellidoPaterno: string,
    public apellidoMaterno: string,
    public nombres: string,
    public dni: string,
    public ruc: string,
    public vigencia: boolean,
    public idTienda: number,
    public idUsuRegistro: number,
    public fecharegistro: any,
    public idUsuActualiza: number,
    public fechaactualiza: any
  ) {}
}
