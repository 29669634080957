export class Inventario {
  constructor(
    public id: number,
    public stock: any,
    public precio: any,
    public idProducto: number,
    public idTienda: number,
    public idProveedor: number,
    public idUsuRegistro: number,
    public fecharegistro: any,
    public idUsuActualiza: number,
    public fechaactualiza: any,
    public producto: string,
    public unidad: string,
    public envase: string,
    public categoria: string,
    public proveedor: string
  ) {}
}
