import {Component,  Input} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector:'footer',
  templateUrl:'./footer.component.html'
})

export class FooterComponent {
  private _name='';
  public titulo:string;
  public ruta:string;
  public anio:string;
  public state_back_top:boolean =false;
  @Input() tituloEvent : string;
  @Input() ruteEvent : string;
  constructor(
    // private _router : ActivatedRoute,
    private router:Router
  ){
    let today = new Date();
    let year = today.getFullYear();
    this.anio = year.toString();
  }
}
