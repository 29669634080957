import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Tienda } from "src/app/models/tienda";
import { Personal } from "src/app/models/personal";
import { Usuario } from "src/app/models/usuario";
import { Perfil } from "src/app/models/perfil";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "regusuarioperfil",
  templateUrl: "./regusuarioperfil.component.html",
})
export class RegUsuarioComponent implements OnInit {
  public title: string;
  public rute: string;

  public _bancant: boolean = false;
  public personal_lst: Array<Personal>;
  public personal_reg: Personal;
  public tienda_lst: Array<Tienda>;
  public tiendaasig_lst: Array<Tienda>;
  public tienda_reg: Tienda;
  public usuario_lst: Array<Usuario>;
  public usuario_reg: Usuario;
  public perfil_lst: Array<Perfil>;
  public perfilasig_lst: Array<Perfil>;
  public perfil_reg: Perfil;
  public _ver: boolean = true;
  public _verus: boolean = true;
  public reg_usuario: boolean = false;
  public reg_permiso: boolean = false;
  public showgenera: boolean = true;
  public _bancantus: boolean = false;
  public usuario_tienda: string = "";
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Registro de usuarios y permisos";
    this.rute = "tda/usuarioperfil";
    // this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
    this._ver = true;
    this.personal_lst = new Array<Personal>();
    this.usuario_lst = new Array<Usuario>();
    this.tienda_lst = new Array<Tienda>();
    this.tiendaasig_lst = new Array<Tienda>();
    this.perfil_lst = new Array<Perfil>();
    this.perfilasig_lst = new Array<Perfil>();
    this.perfil_reg = new Perfil(0, "", "", false);
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      false,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
  }

  ngOnInit() {
    console.log("datosprov.component cargado!");
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.refrescar();
  }

  refrescar() {
    if (!this.reg_usuario) {
      this.listar_personal();
    } else {
      this.listar_usuarios();
    }
  }

  public async generateuser() {
    console.log("prueba");
    console.log(this.personal_reg);
    let original:any = ['á','é', 'í', 'ó', 'ú'];
    let ascii:any = ['a','e', 'i', 'o', 'u'];
    if (localStorage.getItem("token_tda") !== null) {
      let cant_apepaterno = this.personal_reg.per_apellidopaterno.length;
      let cant_letapepat = 6;
      let cant_letapemat = 1;
      if (cant_apepaterno < 6) {
        cant_letapepat = cant_apepaterno;
        cant_letapemat = cant_letapemat + (6 - cant_apepaterno);
      }
      this.usuario_reg.us_usuario = (
        this.personal_reg.per_nombre.substr(0, 1) +
        this.personal_reg.per_apellidopaterno.substr(0, cant_letapepat) +
        this.personal_reg.per_apellidomaterno.substr(0, cant_letapemat)
      ).toLocaleLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');


      let i:number = 0;
      for (i=0; i< original.length; i++) {
        this.usuario_reg.us_usuario = this.usuario_reg.us_usuario.replace(original[i], ascii[i])
      }
      let estado: boolean = true;
      let cont_user = 1;
      let usuario_gen = this.usuario_reg.us_usuario;
      while (estado) {
        console.log(this.usuario_reg.us_usuario);
        let response = await axios.get(
          this.constants.apiUrl +
            "check_user?usuario=" +
            this.usuario_reg.us_usuario +
            "&token=" +
            localStorage.getItem("token_tda")
        );
        estado = response.data.estado;
        if (estado === false) break;
        let long_user = this.usuario_reg.us_usuario.length;
        console.log(long_user);
        if (long_user === 8) {
          usuario_gen = this.usuario_reg.us_usuario + cont_user.toString();
        } else {
          cont_user =
            parseInt(this.usuario_reg.us_usuario.substr(8, long_user - 8)) + 1;
          usuario_gen =
            this.usuario_reg.us_usuario.substr(0, 8) + cont_user.toString();
        }
        this.usuario_reg.us_usuario = usuario_gen;
      }
    } else {
      swal("Token expirado, la sesión finalizará.", {
        icon: "info",
      }).then(() => {
        this.constants.cerrar_sesiontda();
      });
    }
  }

  async listar_personal() {
    this._bancant = false;
    this.spinner.show();
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_personal");
    this.personal_lst = new Array<Personal>();
    await axios
      .post(this.constants.apiUrl + "lista_personal", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let personal_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in personal_lst_aux) {
            let personal: Personal = new Personal(
              personal_lst_aux[element].id,
              personal_lst_aux[element].nombre,
              personal_lst_aux[element].apellidopaterno,
              personal_lst_aux[element].apellidomaterno,
              personal_lst_aux[element].email,
              personal_lst_aux[element].celular,
              personal_lst_aux[element].tienda == 1 ? true : false,
              personal_lst_aux[element].idempresa,
              personal_lst_aux[element].vigencia == 1 ? true : false,
              null,
              null,
              null,
              null
            );
            this.personal_lst.push(personal);
          }
          if (this.personal_lst.length > 0) this._bancant = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          swal("Datos de personal ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async listar_usuarios() {
    this._bancantus = false;
    this.spinner.show();
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idpersonal: this.personal_reg.per_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_usuarios_tda");
    this.usuario_lst = new Array<Usuario>();
    await axios
      .post(this.constants.apiUrl + "lista_usuarios_tda", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let usuario_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in usuario_lst_aux) {
            let usuario: Usuario = new Usuario(
              usuario_lst_aux[element].id,
              usuario_lst_aux[element].usuario,
              "",
              usuario_lst_aux[element].vigencia,
              0,
              0,
              this.personal_reg.per_id,
              false,
              "",
              null,
              null,
              null,
              null,
              this.personal_reg.per_apellidopaterno,
              this.personal_reg.per_apellidomaterno,
              this.personal_reg.per_nombre,
              "",
              false,
              0,
              "",
              "",
              this.personal_reg.per_email,
              this.personal_reg.per_celular
            );
            this.usuario_lst.push(usuario);
          }
          if (this.usuario_lst.length > 0) this._bancantus = true;
        } else {
          swal("Datos de usuario ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  agregarusuario(personal: Personal) {
    this._ver = true;
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.personal_reg = personal;
    this.reg_usuario = true;
    this.listar_usuarios();
    this.tiendaasig_lst = new Array<Tienda>();
    this.obtener_datos_tienda(0);
  }
  async habilitar_deshabilitar(personal: Personal, estado: boolean) {
    this.spinner.show();
    let request = {
      id: personal.per_id,
      vigencia: estado,
    };
    console.log("TIENDA " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "actualizar_estado_personal", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "success").then(
            () => {
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async habilitar_deshabilitar_us(usuario: Usuario, estado: boolean) {
    this.spinner.show();
    let request = {
      id: usuario.us_id,
      vigencia: estado,
    };
    console.log("USUARIO " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "actualizar_estado_usuario", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "success").then(
            () => {
              this.refrescar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  editar(personal: Personal) {
    this._ver = false;
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.personal_reg = personal;
  }

  async registrar() {
    this.spinner.show();
    let request = {
      id: this.personal_reg.per_id,
      nombre: this.personal_reg.per_nombre,
      apellidopaterno: this.personal_reg.per_apellidopaterno,
      apellidomaterno: this.personal_reg.per_apellidomaterno,
      email: this.personal_reg.per_email,
      celular: this.personal_reg.per_celular,
      tienda: 1,
      idempresa: this.constants.usuario_tda.us_idTienda,
    };
    console.log("PERSONAL " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_personal", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "success").then(
            () => {
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Personal", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }
  async registrar_usu() {
    this.spinner.show();
    // this.validar();
    // if (this._banval) {
    let tiendas = Array<Tienda>();
    this.tiendaasig_lst.forEach((element) => {
      if (element.tda_idNegocio == 0) {
        tiendas.push(element);
      }
    });
    let request = {
      id: this.usuario_reg.us_id,
      usuario: this.usuario_reg.us_usuario,
      idNegocio: this.constants.usuario_tda.us_idTienda,
      idTiendaPer: this.personal_reg.per_id,
      tipo: 0,
      tiendas: tiendas,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "created_user_tda", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "success").then(
            () => {
              this.cancelar_usu();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Usuario", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    // } else {
    //   this.spinner.hide();
    //   swal("Registro de Usuario!", this._mensajeval, "info");
    // }
  }

  cancelar() {
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this._ver = true;
    this.refrescar();
  }

  cancelar_usu() {
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      false,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this.refrescar();
    this.tiendaasig_lst = new Array<Tienda>();
    this._ver = true;
    this._verus = true;
  }
  return() {
    this.personal_reg = new Personal(
      0,
      "",
      "",
      "",
      "",
      "",
      false,
      0,
      true,
      null,
      null,
      null,
      null
    );
    this.usuario_reg = new Usuario(
      0,
      "",
      "",
      false,
      0,
      0,
      0,
      false,
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      false,
      0,
      "",
      "",
      "",
      ""
    );
    this.perfil_reg = new Perfil(0, "", "", false);
    this._ver = true;
    this._verus = true;
    this.reg_usuario = false;
    this.reg_permiso = false;
    this.refrescar();
  }
  nuevo() {
    if (!this.reg_usuario) {
      this.personal_reg = new Personal(
        0,
        "",
        "",
        "",
        "",
        "",
        false,
        0,
        true,
        null,
        null,
        null,
        null
      );
      this._ver = false;
    } else {
      this.usuario_reg = new Usuario(
        0,
        "",
        "",
        false,
        0,
        0,
        0,
        false,
        "",
        null,
        null,
        null,
        null,
        "",
        "",
        "",
        "",
        false,
        0,
        "",
        "",
        "",
        ""
      );
      this.showgenera = true;
      this._ver = false;
      this._verus = false;
    }
  }

  async deshabilitar_habilitar_tda(estado: boolean, $tienda: Tienda) {
    this.spinner.show();
    let request = {
      id: $tienda.tda_idNegocio,
      vigencia: estado,
    };
    console.log("USUARIO " + JSON.stringify(request));
    console.log("USUARIO 2 " + JSON.stringify(this.usuario_reg));
    await axios
      .post(
        this.constants.apiUrl + "actualizar_estado_usuario_tienda",
        request,
        {
          headers: this.constants.headers,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Asignación de Tiendas", response.data.mensaje, "success").then(
            () => {
              this.obtener_datos_tienda(this.usuario_reg.us_id);
            }
          );
        } else {
          this.spinner.hide();
          swal("Asignación de Tiendas", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  async asignartienda(estado: boolean, $tienda: Tienda) {
    if (estado) {
      this.tienda_lst.forEach((element) => {
        if (element.tda_id == this.tienda_reg.tda_id) {
          this.tienda_reg = element;
        }
      });
      if (this.tienda_reg.tda_id == 0) {
        swal("Asignar tienda ", "Seleccione tienda", "info");
      } else {
        this.tienda_reg.tda_idNegocio = 0;
        this.tienda_reg.tda_vigencia = true;
        this.tiendaasig_lst.push(this.tienda_reg);
      }
    } else {
      if ($tienda.tda_idNegocio == 0) {
        this.tiendaasig_lst.forEach((element, index) => {
          if (element == $tienda) {
            this.tiendaasig_lst.splice(index);
          }
        });
      } else {
        this.deshabilitar_habilitar_tda(estado, $tienda);
      }
    }
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
  }

  seleccionar_tienda(id: number) {
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
    console.log("TIENDA ID " + id);
    let _ban: boolean = true;
    this.tiendaasig_lst.forEach((element) => {
      if (element.tda_id == id) {
        _ban = false;
      }
    });
    if (!_ban) {
      swal("Asignar tienda ", "Tienda ya ha sido asignada", "info").then(() => {
        this.tienda_reg = new Tienda(
          0,
          "",
          0,
          "",
          "",
          "",
          false,
          null,
          null,
          null,
          null, 0
        );
        this.tienda_reg.tda_id = 0;
      });
    } else {
      this.tienda_reg.tda_id = id;
    }

    this.perfil_reg = new Perfil(0, "", "", false);
  }

  otorgarpermisos(tienda: Tienda) {
    this.reg_permiso = true;
    this.tienda_reg = tienda;
    this.usuario_tienda =
      this.usuario_reg.us_usuario + " :: " + tienda.tda_nombre;
    this.perfilasig_lst = new Array<Perfil>();
    this.obtener_perfiles();
    this.obtener_perfiles_usuario(tienda.tda_id);
  }

  async obtener_datos_tienda(idusuario: number) {
    this.spinner.hide();
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idusuario: idusuario,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda");
    if (idusuario == 0) {
      this.tienda_lst = new Array<Tienda>();
    } else {
      this.tiendaasig_lst = new Array<Tienda>();
    }
    await axios
      .post(this.constants.apiUrl + "lista_tienda", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          if (idusuario == 0) {
            let tienda: Tienda = new Tienda(
              0,
              "Seleccione....",
              0,
              "",
              "",
              "",
              true,
              null,
              null,
              null,
              null, 0
            );
            this.tienda_lst.push(tienda);
          }
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre,
              idusuario == 0 ? 0 : tienda_lst_aux[element].idUserTda,
              "",
              "",
              "",
              tienda_lst_aux[element].vigenciaUserTda == 1 ? true : false,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado
            );
            if (idusuario == 0) {
              this.tienda_lst.push(tienda);
            } else {
              this.tiendaasig_lst.push(tienda);
              console.log(
                "USUARIO TIENDA ASIGNADA: " +
                  JSON.stringify(this.tiendaasig_lst)
              );
            }
          }
        } else {
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  vertiendas($usuario: Usuario) {
    this.showgenera = false;
    this._ver = false;
    this._verus = false;
    this.obtener_datos_tienda($usuario.us_id);
    this.obtener_datos_tienda(0);
    this.usuario_reg = $usuario;
  }

  public async obtener_perfiles() {
    this.spinner.hide();
    let request = {
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_perfiles");
    this.perfil_lst = new Array<Perfil>();
    await axios
      .post(this.constants.apiUrl + "lista_perfiles", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let perfil_lst_aux = response.data.data;
        let perfil: Perfil = new Perfil(
          0,
          "Seleccione...",
          "Seleccione...",
          true
        );
        this.perfil_lst.push(perfil);
        if (response.data.estado) {
          for (let element in perfil_lst_aux) {
            let perfil: Perfil = new Perfil(
              perfil_lst_aux[element].id,
              perfil_lst_aux[element].codigo,
              perfil_lst_aux[element].descripcion,
              perfil_lst_aux[element].vigencia
            );
            this.perfil_lst.push(perfil);
          }
        } else {
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  public async obtener_perfiles_usuario(idtienda: number) {
    this.spinner.hide();
    console.log(JSON.stringify(this.constants.usuario_tda));
    let request = {
      idtienda: idtienda,
      idusuario: this.usuario_reg.us_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_perfiles_usuario_gen");
    this.perfil_lst = new Array<Perfil>();
    await axios
      .post(this.constants.apiUrl + "lista_perfiles_usuario_gen", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log("PERFILES  " + JSON.stringify(response.data));
        this.spinner.hide();
        let perfil_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in perfil_lst_aux) {
            let perfil: Perfil = new Perfil(
              perfil_lst_aux[element].id,
              perfil_lst_aux[element].idPerfil,
              perfil_lst_aux[element].descripcion,
              perfil_lst_aux[element].vigencia
            );
            this.perfilasig_lst.push(perfil);
          }
        } else {
          swal("Asignación de permisos ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async deshabilitar_habilitar_permiso(estado: boolean, $perfil: Perfil) {
    this.spinner.show();
    let request = {
      id: $perfil.pf_ruta,
      vigencia: estado,
    };
    console.log("USUARIO TIENDA PERFIL " + JSON.stringify(request));
    console.log("USUARIO TIENDA PERFIL 2 " + JSON.stringify(this.usuario_reg));
    await axios
      .post(
        this.constants.apiUrl + "actualizar_estado_perfil_usuario_tienda",
        request,
        {
          headers: this.constants.headers,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Asignación de Permisos", response.data.mensaje, "success").then(
            () => {
              this.perfilasig_lst = new Array<Perfil>();
              this.obtener_perfiles();
              this.obtener_perfiles_usuario(this.tienda_reg.tda_id);
            }
          );
        } else {
          this.spinner.hide();
          swal("Asignación de Permisos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  asignarpermiso(estado: boolean, $perfil: Perfil) {
    if (estado) {
      this.perfil_lst.forEach((element) => {
        if (element.pf_id == this.perfil_reg.pf_id) {
          this.perfil_reg = element;
        }
      });
      if (this.perfil_reg.pf_id == 0) {
        swal("Asignar permiso ", "Seleccione módulo", "info");
      } else {
        this.perfil_reg.pf_ruta = "0";
        this.perfil_reg.pf_vigencia = true;
        this.perfilasig_lst.push(this.perfil_reg);
      }
    } else {
      if ($perfil.pf_ruta == "0") {
        this.perfilasig_lst.forEach((element, index) => {
          if (element == $perfil) {
            this.perfilasig_lst.splice(index);
          }
        });
      } else {
        this.deshabilitar_habilitar_permiso(estado, $perfil);
      }
    }
    this.perfil_reg = new Perfil(0, "", "", false);
  }

  seleccionar_perfil(id: number) {
    this.perfil_reg = new Perfil(0, "", "", false);
    let _ban: boolean = true;
    this.perfilasig_lst.forEach((element) => {
      if (element.pf_id == id) {
        _ban = false;
      }
    });
    if (!_ban) {
      this.perfil_reg.pf_id = 0;
      swal("Asignar permiso ", "Módulo ya ha sido asignado.", "info");
    } else {
      this.perfil_reg.pf_id = id;
    }
  }

  async registrar_permiso() {
    this.spinner.show();
    // this.validar();
    // if (this._banval) {
    let modulos = new Array<Perfil>();
    this.perfilasig_lst.forEach((element) => {
      if (element.pf_ruta == "0") {
        modulos.push(element);
      }
    });
    let request = {
      idUsuario: this.usuario_reg.us_id,
      idTienda: this.tienda_reg.tda_id,
      modulos: modulos,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "created_permisos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Permisos", response.data.mensaje, "success").then(
            () => {
              this.cancelar_permiso();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Permisos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    // } else {
    //   this.spinner.hide();
    //   swal("Registro de Usuario!", this._mensajeval, "info");
    // }
  }

  cancelar_permiso() {
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
    this.reg_permiso = false;
    this.usuario_tienda = "";
    this._ver = true;
  }
}
