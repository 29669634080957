export class Producto {
  constructor(
    public prod_id: number,
    public prod_codigo: string,
    public prod_descripcion: string,
    public prod_imagen: any,
    public prod_cantidad: any,
    public prod_precio: any,
    public prod_preciototal: any,
    public prod_idProveedor: number,
    public prod_idUnidad: number,
    public prod_unidad: string,
    public prod_idEnvase: number,
    public prod_envase: string,
    public prod_vigencia: boolean,
    public prod_fecharegistro: Date,
    public prod_adminregistro: number,
    public prod_fechaactualiza: Date,
    public prod_adminactualiza: number,
    public prod_imagen_url: any,
    public prod_idCategoria: number,
    public prod_categoria: string,
    public prod_superior: number,
    public prod_stock: any,
    public prod_cantpedido: number,
    public prod_idProductoGral: number
  ) {}
}

export interface IProducto {
  prod_id: number;
  prod_codigo: string;
  prod_descripcion: string;
  prod_imagen: any;
  prod_cantidad: any;
  prod_precio: any;
  prod_preciototal: any;
  prod_idProveedor: string;
  prod_idUnidad: number;
  prod_unidad: string;
  prod_idEnvase: number;
  prod_envase: string;
  prod_vigencia: boolean;
  prod_fecharegistro: Date;
  prod_adminregistro: number;
  prod_fechaactualiza: Date;
  prod_adminactualiza: number;
  prod_imagen_url: any;
  prod_idCategoria: number;
  prod_categoria: string;
  prod_superior: number;

}
