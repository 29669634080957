import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "configtda",
  templateUrl: "./configtda.component.html",
})
export class ConfigTdaComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipoorg: number = 0;
  public organizacion_reg: Organizacion;
  public claveactual_bs: string;
  public nuevaclave_bs: string;
  public repiteclave_bs: string;
  public documento: String;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Configuración de datos";
    this.rute = "tda/configuracion";
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.constants.permisoconfig_existe(
      this.constants.usuario_tda.us_principal
    );
    this.constants.sesiontda(this.rute);
    this.constants.obtener_perfiles();
    this.constants.listado_ciudades();
  }

  ngOnInit() {
    console.log("datosprov.component cargado!");
    if (this.constants.usuario_tda.us_principal) {
      this.constants.usuario_tda = JSON.parse(
        localStorage.getItem("logindatos_tda")
      );
      this.organizacion_reg = new Organizacion(
        0,
        "",
        "-1",
        "",
        "",
        "",
        "",
        0,
        "",
        0,
        "",
        "",
        "",
        false,
        false,
        false,
        false,
        "",
        "",
        false,
        null,
        null,
        null,
        null
      );
      this.tipoorg = 0;
      this.obtener_datos();
    }
  }

  async obtener_datos() {
    this.spinner.show();
    console.log("USUARIO " + JSON.stringify(this.constants.usuario_tda));
    let request = {
      tipo: this.tipoorg,
      id: this.constants.usuario_tda.us_idTienda,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_proveedor");
    await axios
      .post(this.constants.apiUrl + "lista_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let organizacion_aux = response.data.data;
        if (response.data.estado) {
          this.organizacion_reg = new Organizacion(
            organizacion_aux.id,
            organizacion_aux.empresa,
            organizacion_aux.tipodocumento,
            organizacion_aux.documento == null ||
            organizacion_aux.documento == "null"
              ? ""
              : organizacion_aux.documento,
            organizacion_aux.representante,
            organizacion_aux.email,
            organizacion_aux.telefono,
            organizacion_aux.negocio,
            organizacion_aux.otrosector,
            organizacion_aux.sector,
            organizacion_aux.pais,
            organizacion_aux.ciudad,
            organizacion_aux.codigopostal == null ||
            organizacion_aux.codigopostal == "null"
              ? ""
              : organizacion_aux.codigopostal,
            organizacion_aux.proveedor,
            organizacion_aux.auspiciador,
            organizacion_aux.patrocinador,
            organizacion_aux.vigencia,
            organizacion_aux.mensaje,
            organizacion_aux.codigoconfirmacion,
            organizacion_aux.usuariocreado,
            organizacion_aux.fechavalidacion,
            organizacion_aux.adminvalidacion,
            organizacion_aux.fechaactualiza,
            organizacion_aux.adminactualiza
          );
          this.cambiar_documento(organizacion_aux.tipodocumento);
        } else {
          swal("Datos de organización", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  cancelar_registro() {
    this.obtener_datos();
    this.claveactual_bs = "";
    this.repiteclave_bs = "";
    this.nuevaclave_bs = "";
  }

  async actualizar_clave() {
    swal("Cambiar Contraseña", "Operación por desarrollar...", "info");
  }

  async actualizar_organizacion() {
    this.spinner.show();
    this.tipoorg = this.constants.usuario_tda.us_tipo;
    let request = {};
    request = {
      id: this.organizacion_reg.org_id,
      tipodocumento: this.organizacion_reg.org_tipodocumento,
      documento: this.organizacion_reg.org_documento,
      empresa: this.organizacion_reg.org_empresa,
      representante: this.organizacion_reg.org_representante,
      telefono: this.organizacion_reg.org_telefono,
      email: this.organizacion_reg.org_email,
      negocio: this.organizacion_reg.org_negocio,
      pais: this.organizacion_reg.org_pais,
      ciudad: this.organizacion_reg.org_ciudad,
      codigopostal: this.organizacion_reg.org_codigopostal,
      tipo: 0,
    };

    // console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_proveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.spinner.hide();
        console.log(response.data);
        if (response.data.estado) {
          swal("Configuración de Datos", response.data.mensaje, "success").then(
            () => {
              this.obtener_datos();
            }
          );
        } else {
          swal("Configuración de Datos", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  cambiar_documento(tipo: string) {
    if (tipo == "-1") {
      this.documento = "*Seleccione tipo documento";
    } else if (tipo == "0") {
      this.documento = "DNI";
    } else {
      this.documento = "RUC";
    }
  }
}
