import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./components/sites/home.component";
import { OlvideClaveComponent } from "./components/sites/olvideclave.component";
import { DashboardComponent } from "./components/sites/dashboard.component";
import { ConfigTdaComponent } from "./components/sites/configtda.component";
import { BandejaComponent } from "./components/sites/bandeja.component";
import { RegTiendaComponent } from "./components/sites/modules/regtienda.component";
import { RegUsuarioComponent } from "./components/sites/modules/regusuarioperfil.component";
import { CierreCajaComponent } from "./components/sites/modules/regcierrecaja.component";
import { RegCobrosComponent } from "./components/sites/modules/regcobros.component";
import { RegDevolucionComponent } from "./components/sites/modules/regdevolucion.component";
import { IngresoProdComponent } from "./components/sites/modules/regingresoprod.component";
import { InventarioComponent } from "./components/sites/modules/reginventario.component";
import { RegPagosComponent } from "./components/sites/modules/regpagos.component";
import { RegPedidoComponent } from "./components/sites/modules/regpedidos.component";
import { RegVentaComponent } from "./components/sites/modules/regventa.component";
import { RegProductoComponent } from "./components/sites/modules/regproducto.component";
import { BeneficiosComponent } from "./components/sites/modules/regbeneficios.component";
import { EstadisticasComponent } from "./components/sites/modules/regestadisticas.component";
import { NuevaClaveComponent } from "./components/sites/nuevaclave.component";
const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "inicio", component: HomeComponent },
  { path: "olvideclave", component: OlvideClaveComponent },
  { path: "nuevapass", component: NuevaClaveComponent },
  { path: "tda/dashboard", component: DashboardComponent },
  { path: "tda/configuracion", component: ConfigTdaComponent },
  { path: "tda/bandeja", component: BandejaComponent },
  { path: "tda/tienda", component: RegTiendaComponent },
  { path: "tda/usuarioperfil", component: RegUsuarioComponent },
  { path: "tda/cobros", component: RegCobrosComponent },
  { path: "tda/devolucion", component: RegDevolucionComponent },
  { path: "tda/pagos", component: RegPagosComponent },
  { path: "tda/pedidos", component: RegPedidoComponent },
  { path: "tda/ingresoproduct", component: IngresoProdComponent },
  { path: "tda/venta", component: RegVentaComponent },
  { path: "tda/cierrecaja", component: CierreCajaComponent },
  { path: "tda/inventario", component: InventarioComponent },
  { path: "tda/producto", component: RegProductoComponent },
  { path: "tda/beneficios", component: BeneficiosComponent },
  { path: "tda/estadisticas", component: EstadisticasComponent },

  { path: "**", component: HomeComponent },
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

export const AppRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
});
