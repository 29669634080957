import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CambioPasswordComponent } from "../cambiopassword.component";
import { ConstantsService } from "../../../constants.service";
import { Usuario } from "../../../models/usuario";
import { Tienda } from "src/app/models/tienda";
import axios from "axios";
const MODALS = {
  changepass: CambioPasswordComponent,
};

@Component({
  selector: "menutda",
  templateUrl: "./menutda.component.html",
})
export class MenuTdaComponent{
  @Input() usuario_men: Usuario;
  withAutofocus = `<button type="button" ngbAutofocus class="btn btn-danger"
      (click)="modal.close('Ok click')">Ok</button>`;
  public _name = "";
  public tienda_dato: number = 0;
  public tienda_lst: Array<Tienda>;
  constructor(
    // public _router : ActivatedRoute,
    public _modalService: NgbModal,
    public router: Router,
    public constants: ConstantsService
  ) {
    this.usuario_men = this.constants.usuario_tda;
    // this.constants.perfilpermiso_lst = new Array<Perfil>();
    // this.constants.obtener_perfiles();
    this.tienda_lst = new Array<Tienda>();
  }

  ngOnInit() {
    this.obtener_datos_tienda().then(() => {
      let tienda_aux: Tienda = JSON.parse(
        localStorage.getItem("datostienda_tda")
      );
      this.tienda_dato = tienda_aux.tda_id;
    });
  }

  open(name: string) {
    this._modalService.open(MODALS[name]);
  }

  async obtener_datos_tienda() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idusuario: this.constants.usuario_tda.us_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda_usuario");
    this.tienda_lst = new Array<Tienda>();
    await axios
      .post(this.constants.apiUrl + "lista_tienda_usuario", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          let tienda: Tienda;
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre,
              0,
              tienda_lst_aux[element].direccion,
              tienda_lst_aux[element].referencia,
              tienda_lst_aux[element].ciudad,
              true,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado
            );
            this.tienda_lst.push(tienda);
            this.tienda_dato = 0;
          }
        }
      })
      .catch(function (error) {
        console.log("tipo error " + error.message);
      });
  }
  cambiar_tienda() {
    this.tienda_lst.forEach((item, index) => {
      if (item.tda_id.toString() == this.tienda_dato.toString()) {
        localStorage.setItem("datostienda_tda", JSON.stringify(item));
        window.location.reload();
      }
    });
  }
}
