import { Component, OnInit, NgModule } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import 'rxjs/add/operator/filter';
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { ConstantsService } from "src/app/constants.service";
const swal: SweetAlert = _swal as any;
@Component({
  selector: "nuevaclave",
  templateUrl: "./nuevaclave.component.html",
  styleUrls: ["./home.component.css"],
})
export class NuevaClaveComponent implements OnInit {
  recaptchaAvailable = false;
  public title: string;
  public rute: string;
  public pass_usu: String = "";
  public passc_usu: String = "";
  public id_usu: number =  0
  public tipo_usu: number =  0
  public codigocambio: string =  ""
  public _load: boolean = false;
  constructor(
    private router: Router,
    private actroute: ActivatedRoute,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Nueva contraseña";
    this.rute = "/nuevapass";
    // this.id_usu = parseInt(atob(this.actroute.snapshot.paramMap.get("id")));
    // this.tipo_usu = parseInt(atob(this.actroute.snapshot.paramMap.get("tipo")));
    // this.codigocambio = atob(
    //   this.actroute.snapshot.paramMap.get("codigoconfirmacion")
    // );
  }

  ngOnInit() {
    console.log("login.component cargado!");
    this.cancel()
    this.actroute.queryParams.filter(params => params.codigoconfirmacion)
      .subscribe(params => {
        console.log(params.codigoconfirmacion)
        console.log(atob(
          params.codigoconfirmacion
        ))
        this.codigocambio = atob(
          params.codigoconfirmacion
        );
      }
    );
    this.actroute.queryParams.filter(params => params.tipo)
      .subscribe(params => {
        this.tipo_usu = parseInt(atob(params.tipo));
      }
    );
    this.actroute.queryParams.filter(params => params.id)
      .subscribe(params => {
        this.id_usu = parseInt(atob(params.id));
      }
    );
  }

  async login(captchaResponse: string) {
    this._load = true;

      // this.spinner.show();

      let _ban = true;
      let _mensaje = "";
      let data = {
        nuevapass: this.pass_usu,
        confimapass: this.passc_usu,
        tokenrecaptcha: captchaResponse,
        id: this.id_usu,
        codigocambio: this.codigocambio
      };
      // this.spinner.show();
      if (this.pass_usu.trim() == "" && this.passc_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese su contraseña nueva.";
      } else if (this.pass_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese su contraseña.";
      } else if (this.passc_usu.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Confirme su contraseña.";
      }else if (this.pass_usu.trim() != this.passc_usu.trim()) {
        this._load = false;
        _ban = false;
        _mensaje = "Las contraseñas son distintas.";
      }else if (this.codigocambio.trim() == "") {
        this._load = false;
        _ban = false;
        _mensaje = "Ingrese ccódigo de confirmación.";
      }
      if (_ban) {
        console.log("TOKEN LOGIN: " + JSON.stringify(data));
        await axios
          .post(this.constants.apiUrl + "nueva_clave", data)
          .then((response) => {
            console.log(response.data);
            let datos = response.data;
            if (datos.estado) {
              this.spinner.hide();
              this._load = false;
              swal("Actualizar contraseña", datos.mensaje, "success").then(
                (response) => {

                  switch(this.tipo_usu){
                    case 0: location.href = this.constants.indextienda; break;
                    case 1: location.href = this.constants.index + '#/business'; break;
                    case 2: location.href = this.constants.index + '#/business'; break;
                    case 3: location.href = this.constants.index + '#/business'; break;
                    case 4: location.href = this.constants.index + '#/adminlogin'; break;
                  }
                  this.cancel();
                  // location.href = this.constants.indextienda + "tda/dashboard";
                }
              );
            } else {
              this.spinner.hide();
              this._load = false;
              swal("Actualizar contraseña", datos.mensaje, "info");
            }
          })
          .catch(function (error) {
            this._load = false;
            this.spinner.hide();
            swal("Ha ocurrido un error al registrar!", error.message, "error");
            console.log(error);
          });
      } else {
        this.spinner.hide();
        this._load = false;
        swal("Actualizar contraseña", _mensaje, "info");
      }

  }

  cancel() {
    this.pass_usu = "";
    this.passc_usu = "";
    this.codigocambio= "";
  }
}
