export class Notificacion {
  constructor(
    public id: number,
    public fecha: any,
    public asunto: string,
    public mensaje: string,
    public idProveedor: number,
    public empresa: string,
    public tipo: string
  ) {}
}
