import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Organizacion } from "src/app/models/organizacion";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Tienda } from "src/app/models/tienda";
import { Perfil } from "src/app/models/perfil";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "regtienda",
  templateUrl: "./regtienda.component.html",
})
export class RegTiendaComponent implements OnInit {
  public title: string;
  public rute: string;
  public tipoorg: number = 0;
  public organizacion_reg: Organizacion;
  public claveactual_bs: string;
  public nuevaclave_bs: string;
  public repiteclave_bs: string;
  public documento: String;

  public _bancant: boolean = false;
  public tienda_lst: Array<Tienda>;
  public tienda_reg: Tienda;
  public _ver: boolean = true;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService
  ) {
    this.title = "Registro de Tienda";
    this.rute = "tda/tienda";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
    this._ver = true;
    this.tienda_lst = new Array<Tienda>();
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
  }

  ngOnInit() {
    console.log("datosprov.component cargado!");
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.listar_tiendas();
  }

  async listar_tiendas() {
    this._bancant = false;
    this.spinner.hide();
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idnegocio: this.constants.usuario_tda.us_idTienda,
      idusuario: 0,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_tienda");
    this.tienda_lst = new Array<Tienda>();
    await axios
      .post(this.constants.apiUrl + "lista_tienda", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let tienda_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in tienda_lst_aux) {
            let tienda: Tienda = new Tienda(
              tienda_lst_aux[element].id,
              tienda_lst_aux[element].nombre,
              tienda_lst_aux[element].idNegocio,
              tienda_lst_aux[element].direccion,
              tienda_lst_aux[element].referencia,
              tienda_lst_aux[element].ciudad,
              tienda_lst_aux[element].vigencia,
              null,
              null,
              null,
              null, tienda_lst_aux[element].fiado,
            );
            this.tienda_lst.push(tienda);
          }
          if (this.tienda_lst.length > 0) this._bancant = true;
        } else {
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }
  async habilitar_deshabilitar(tienda: Tienda, estado: boolean) {
    this.spinner.show();
    let request = {
      id: tienda.tda_id,
      vigencia: estado,
    };
    console.log("TIENDA " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "actualizar_estado_tienda", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Tienda", response.data.mensaje, "success").then(
            () => {
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Tienda", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  editar(tienda: Tienda) {
    this._ver = false;
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
    this.tienda_reg = tienda;
  }

  async registrar() {
    this.spinner.show();
    let request = {
      id: this.tienda_reg.tda_id,
      idNegocio: this.constants.usuario_tda.us_idTienda,
      nombre: this.tienda_reg.tda_nombre,
      direccion: this.tienda_reg.tda_direccion,
      referencia: this.tienda_reg.tda_referencia,
      ciudad: this.tienda_reg.tda_ciudad,
      fiado: this.tienda_reg.tda_fiado,
    };
    console.log("TIENDA " + JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "registro_tienda", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.estado) {
          this.spinner.hide();
          swal("Registro de Tienda", response.data.mensaje, "success").then(
            () => {
              let tienda_aux: Tienda = JSON.parse(
                localStorage.getItem("datostienda_tda")
              );
              if(this.tienda_reg.tda_id == tienda_aux.tda_id){
                tienda_aux.tda_direccion = this.tienda_reg.tda_direccion;
                tienda_aux.tda_referencia = this.tienda_reg.tda_referencia;
                tienda_aux.tda_ciudad = this.tienda_reg.tda_ciudad;
                localStorage.setItem("datostienda_tda", JSON.stringify(tienda_aux));
              }
              this.cancelar();
            }
          );
        } else {
          this.spinner.hide();
          swal("Registro de Tienda", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  cancelar() {
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      null,
      null,
      null,
      null, 0
    );
    this._ver = true;
    this.listar_tiendas();
  }

  nuevo() {
    this.tienda_reg = new Tienda(
      0,
      "",
      0,
      "",
      "",
      "",
      true,
      null,
      null,
      null,
      null, 0
    );
    this._ver = false;
  }
}
