export class Caja {
  constructor(
    public id: any,
    public indicador: boolean,
    public monto: any,
    public tipo: number,
    public motivo: any,
    public idOrden: number,
    public idTienda: number,
    public pagoConfirma: boolean,
    public cierrecaja: boolean,
    public idUsuRegistro: number,
    public fecharegistro: any,
    public idUsuActualiza: number,
    public fechaactualiza: any,
    public glosa: string,
    public tipomov: string,
  ) {}
}
