import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent {
  validatingForm: FormGroup;
  private _name = "";
  public titulo: string;
  public ruta: string;
  @Output() boton = new EventEmitter<string>();
  @Input() tituloEvent: string;
  @Input() ruteEvent: string;
  constructor(
    // private _router : ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl("", Validators.email),
      loginFormModalPassword: new FormControl("", Validators.required),
    });
  }

  get loginFormModalEmail() {
    return this.validatingForm.get("loginFormModalEmail");
  }

  get loginFormModalPassword() {
    return this.validatingForm.get("loginFormModalPassword");
  }

  public cancel() {
    this.boton.emit("cancel");
  }
}
